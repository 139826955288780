<template>
  <v-container
    fill-height
    fluid
    grid-list-xl
    pb-5
    mb-5
  >
    <v-layout
      white
      wrap
      ma-2
    >
      <material-ProjectForm
        @save="createProject($event)"
      />
      <!-- SNACKBAR -->
      <ui-SnackBarV2
        :text="text"
        :color="color"
        :bottom="bottom"
        :top="top"
        :right="right"
        :left="left"
        :snackbar="snackbar"
      />
    </v-layout>
  </v-container>
</template>

<script>
// Utilities
import SnackBarMixin from '@/mixins/SnackBarMixin'
import { mapActions, mapMutations } from 'vuex'

export default {
  name: 'ProjectCreateForm',

  // components:{Snackbar},
  mixins: [SnackBarMixin],

  data: () => ({
  }),

  methods: {
    ...mapActions('project', ['create_project']),
    ...mapMutations('app', ['showLoader']),

    createProject (projects) {
      this.showLoader()
      this.create_project(projects)
        .then(response => {
          console.log('CREATE', response)
          this.snack({
            position: 'bottom',
            align: 'center',
            color: 'success',
            text: 'Création du projet#' + response.id + ' "' + response.name + '" effectué avec succès'
          })
        })
        .catch(error => {
          console.log('Erreur', error)
          this.snack({
            position: 'bottom',
            align: 'center',
            color: 'error',
            text: 'Création du projet impossible : ' + error.message
          })
        })
        .finally(() => {
          this.showLoader()
        })
    }
  }
}
</script>
